import {decorate, observable, computed} from "mobx";
import {TManufacturer, TManufacturerPart, TUser, TSalesRep} from "./Types";

const LS_SHOW_EARLY_RENEWAL_ITEMS_KEY = 'show_early_renewal_items';

class AppStoreClass {

    initialized:    boolean = false;
    user?:          TUser;
    authToken?:     string;
    authTokenChecked:boolean = false;
    baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:1337' : 'https://maintenance-api.markiiisys.com';
    // baseUrl = 'https://maintenance-api.markiiisys.com';
    manufacturerParts:TManufacturerPart[] = [];
    manufacturers:TManufacturer[] = [];
    salesRep?: TSalesRep;

    showEarlyRenewalItems:boolean;

    constructor() {
        let showRenewalItems = localStorage.getItem(LS_SHOW_EARLY_RENEWAL_ITEMS_KEY);
        this.showEarlyRenewalItems = showRenewalItems === 'true';
    }

    init(){

    }


    get necessaryDataIsLoaded(){
        return true;
    }
}


decorate(AppStoreClass, {
    authToken:      observable,
    authTokenChecked:observable,
    user:           observable,
    necessaryDataIsLoaded: computed,
    manufacturerParts:observable,
    manufacturers:observable,
    showEarlyRenewalItems:observable,
    salesRep:observable,
});


const AppStore = new AppStoreClass();
export default AppStore;