import React, {useState} from 'react';
import {Modal, Popover} from "antd";
import {ReadOutlined} from '@ant-design/icons';

import './styles.scss';

import {TContractLineItem} from "../../../stores/App/Types";
import ContractActions from "../../../actions/ContractActions";
import NotesForm from "./NotesForm";

interface Props {
    contractLineItem: TContractLineItem,
}

export default (props: Props) => {
    const {contractLineItem} = props;
    const [showForm, setShowForm] = useState(false);
    const [curContractLineItem, setCurContractLineItem] = useState(contractLineItem);

    async function saveNotes(contractLineItemId:number, notes:string) {
        let updatedLineItem = await ContractActions.updateNotes(contractLineItemId, notes);
        setCurContractLineItem(updatedLineItem);
    }

    const onIconClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
    }

    const onCancel = () => {
        setShowForm(false);
    }

    const onFormSubmit = async (contractLineItem:TContractLineItem, values: any) => {
        await saveNotes(contractLineItem.id, values.notes);
        setShowForm(false);
    }

    function renderNotes() {
        return (
            <div className='contract-line-item-notes'>
                <div className='action'>Click the Icon to Change Notes</div>
                {curContractLineItem.notes && <div className='notes'>{curContractLineItem.notes}</div>}
            </div>
        )
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <Popover content={renderNotes()} title={'Current Notes'}>
                <ReadOutlined onClick={onIconClick} style={{fontSize: 22, color: 'gray'}}/>
            </Popover>
            <Modal centered title='Set Notes' visible={showForm} destroyOnClose onCancel={onCancel} footer={null}>
                <NotesForm onSubmit={onFormSubmit} contractLineItem={curContractLineItem}/>
            </Modal>
        </div>
    )
}