import React, {useEffect} from 'react';
import {motion} from 'framer-motion';

import './styles.scss';

import {CountUp} from "countup.js";
import {TServiceContract} from "../../../stores/App/Types";

interface Props {
    id:number,
    contracts:TServiceContract[],
    endText:string,
}

export default (props:Props) => {
    const { contracts, endText, id } = props;

    useEffect(() => {
        let count = contracts.length;
        let countUp = new CountUp(`count-up-${id}`, count)
        if (!countUp.error) countUp.start();
    }, [contracts, id])

   function getContractsString(){
        if (contracts.length === 1) return 'Contract Expires';
        else return 'Contracts Expire';
    }

    return (
        <div className='contract-count'>
            <motion.div animate={{opacity: 1, y: 0}}
                                initial={{opacity: 0, y: 25}}
                                transition={{ type:'spring' }} id={`count-up-${id}`} className='count'>

            </motion.div>
            <div className='expires'>
                <motion.div animate={{opacity: 1, y: 0}}
                                initial={{opacity: 0, y: 25}}
                                transition={{ delay:0.1, type:'spring' }} className='expires-on'>{getContractsString()} Within</motion.div>
                <motion.div animate={{opacity: 1, y: 0}}
                                initial={{opacity: 0, y: 25}}
                                transition={{ delay:0.2, type:'spring' }} className='expiry-date'>{endText}</motion.div>
            </div>
        </div>
    )
}