import React from 'react';
import SearchContracts from "../../components/specific/SearchContracts/SearchContracts";
import './styles.scss';

export default () => {
    return (
        <div className='admin-container'>
            <div className='admin-container-content'>
                <SearchContracts/>
            </div>
        </div>
    )
}
