import moment from 'moment';

export const CSCStackedColumnConfig = {
    forceFit: true,
    title: {
        visible: true,
        text: '',
        style: {
            fontSize: 14,
            fontWeight: 500,
            fill: '#505050',
            fontFamily: 'Roboto'
        }
    },
    padding: 'auto',
    data: [],
    xField: 'Date',
    yField: 'Contracts',
    stackField: 'type',
    yAxis: {
        min: 0,
    },
    xAxis: {
        label: {
            formatter: (v: string) => (`${moment(v).utc().format('MMM YYYY')}`)
        },
    },
    label: {
        visible: true,
    },
    legend: {
        marker: {
            symbol:'circle',
            style:{
                marker:'circle'
            }
        }
    },
}