import React, {useState} from 'react';
import {Form, Input, Checkbox} from 'antd';
import {MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import Button from "../../common/Button/Button";
import AppStore from "../../../stores/App/AppStore";
import {EReminderInterval, TReminder} from "../../../stores/App/Types";

interface Props {
    onSubmit:(values:any)=>void
    reminders?:TReminder[],
}

export default (props:Props) => {
    const {onSubmit, reminders} = props;
    const [submitting, setSubmitting] = useState(false);

    const onFinish = async (values:any) => {
        if (onSubmit) {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
        }
    };
    let initialValues = {};

    if (reminders?.[0]?.emails){
        initialValues = {emails: reminders[0].emails.split(',').map(email => ({email}))};
    }
    else if (AppStore.user?.email) initialValues = {
        emails:[{email:AppStore.user.email}]
    }

    if (reminders) {
        initialValues = {...initialValues, intervals: reminders.map(reminder => reminder.reminder_interval)}
    }

    return (
        <Form className='reminders-form' initialValues={initialValues} name="reminders-form"
              onFinish={onFinish} autoComplete="off" preserve={false}>
            <div className='reminders-form-content'>
                <Form.List name="emails">
                    {(fields, {add, remove}) => {
                        return (
                            <div className='email-list'>
                                <div className='reminder-header'>
                                    Send Reminder Emails To:
                                </div>
                                {fields.map(field => (
                                    <div key={field.key} className='single-email'>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'email']}
                                            fieldKey={[field.fieldKey, 'email']}
                                            validateTrigger='onSubmit'
                                            rules={[{
                                                required: true,
                                                message: 'Please enter a valid email',
                                                type: 'email'
                                            }]}
                                        >
                                            <Input className='email-input' size='large' placeholder="Email Address"/>
                                        </Form.Item>

                                        <MinusCircleOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    </div>
                                ))}

                                <Form.Item>
                                    <div className='add-email' onClick={() => add()}>
                                        <PlusCircleOutlined/> Add Email
                                    </div>
                                </Form.Item>
                            </div>
                        );
                    }}
                </Form.List>
                <div className='interval'>
                    <div className='reminder-header'>
                        Send Reminder Emails __ Before Expiration:
                    </div>
                    <Form.Item name='intervals'>
                        <Checkbox.Group>
                            <Checkbox value={EReminderInterval.ThreeMonths}>3 Months</Checkbox>
                            <Checkbox value={EReminderInterval.OneMonth}>1 Month</Checkbox>
                            <Checkbox value={EReminderInterval.TwoWeeks}>2 Weeks</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </div>
            </div>
            <div className='buttons-container'>
                <Form.Item style={{marginBottom: 0}}>
                    <Button loading={submitting} htmlType="submit">
                        Set Reminders
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};