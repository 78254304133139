import React, {useEffect, useState} from 'react';
import './styles.scss';
import { Select, Input } from "antd";
import {SearchOutlined} from '@ant-design/icons';
import AdminActions from "../../../stores/Admin/AdminActions";
import {ESearchBy} from "../../../stores/Admin/Types";
import ContractResult from './ContractResult';
import {TServiceContract} from "../../../stores/App/Types";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";

const {Option} = Select;
const {Search} = Input;

const lastAdminSearchKey = 'm3_maintenance_last_admin_search';
const lastAdminSearchByKey = 'm3_maintenance_last_admin_search_by';

export default () => {
    const [searchBy, setSearchBy] = useState(localStorage.getItem(lastAdminSearchByKey) as ESearchBy || ESearchBy.ServiceContractID);
    const [searchingContracts, setSearchingContracts] = useState(false);
    const [contracts, setContracts] = useState<TServiceContract[]>([]);

    useEffect(()=> {
        let onLoadValue = localStorage.getItem(lastAdminSearchKey);
        if (onLoadValue) onSearch(onLoadValue);
        // eslint-disable-next-line
    }, [])

    const selectBefore = (
        <Select defaultValue={searchBy} onSelect={option => setSearchBy(option)} style={{width: 175}}>
            {/*<Option value={ESearchBy.Serial}>Serial</Option>*/}
            <Option value={ESearchBy.POInvoiceNumber}>Mark III PO</Option>
            <Option value={ESearchBy.ServiceContractID}>Service Contract ID</Option>
        </Select>
    );

    async function onSearch(value:string){
        setSearchingContracts(true);
        let contracts = await AdminActions.searchAllContracts(value, searchBy);

        localStorage.setItem(lastAdminSearchKey, value);
        localStorage.setItem(lastAdminSearchByKey, searchBy);

        setContracts(contracts || []);
        setSearchingContracts(false);
    }

    function getContent(){
        if (searchingContracts){
            return <DefaultLoader text='Loading Contract Data'/>;
        } else if (contracts){
            return (
                <div className='contract-results'>
                    {contracts.map(contract => <ContractResult key={contract.id} contract={contract}/>)}
                </div>
            )
        } else return null;
    }

    return (
        <div className='search-contracts'>
            <div className='search'>
                <Search size='large' addonBefore={selectBefore} defaultValue={localStorage.getItem(lastAdminSearchKey) || undefined}
                        placeholder='Search...' onSearch={onSearch} enterButton={
                    <>
                        <SearchOutlined style={{marginRight: 9, fontSize: 18}}/>
                        Search Contracts
                    </>
                }/>
                {getContent()}
            </div>
        </div>
    )
}