import React from 'react';
import {Table} from 'antd';
import {OrderedListOutlined} from '@ant-design/icons';
import {parse} from 'json2csv';

import './styles.scss';

import {ServiceContractColumns} from "./TableConfig";
import {TContractLineItem, TServiceContract} from "../../../stores/App/Types";
import LineItemsTable from "./LineItemsTable";
import Button from '../../common/Button/Button';
import ContractActions from "../../../actions/ContractActions";
import {BrowserUtils} from "../../../global/utils/Browser";

interface Props {
    contracts:TServiceContract[];
    showEarlyRenewalItems?:boolean;
}

export const ContractsTable = (props:Props) => {
    const { contracts, showEarlyRenewalItems } = props;

    function getDataSource(){
        return contracts
            .sort((a,b) =>
                new Date(a.contract_end_date).getTime() - new Date(b.contract_end_date).getTime());
    }

    async function onClickDownloadCSV(serviceContract:TServiceContract){
        let lineItems = await ContractActions.getContractLineItems(serviceContract.id as any, -1)
        if (lineItems){
            let s = lineItems.map((lineItem:TContractLineItem) => ({
                Part: lineItem.manufacturer_part.manufacturer_part_name,
                'Part #': lineItem.manufacturer_part.hw_sw_reference,
                Description: lineItem.part_description?.part_description,
                Serial: lineItem.serial_number,
                Quantity: lineItem.quantity,
                'EoS Availability': lineItem.manufacturer_part.end_of_life_date,
                Comments: lineItem.comments,
                Renewed: lineItem.renewed ? 'Yes' : 'No',
            }))
            let csv = await parse(s);
            const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            BrowserUtils.downloadFile(
                `${serviceContract.po_invoice_number}_line_items_${new Date().toISOString()}.csv`,
                window.URL.createObjectURL(csvData)
            );
        }
    }

    const expandable = {
        expandRowByClick: true,
        expandedRowRender: (record:any) => {
            return (
                <div>
                    <Button onClick={()=>onClickDownloadCSV(record)} style={{marginBottom:12}} icon={<OrderedListOutlined/>}>Download All Items (.csv)</Button>
                    <LineItemsTable serviceContractId={record.id} showEarlyRenewalItems={showEarlyRenewalItems}/>
                </div>
            )
        }
    }

    return (
        <div className='contracts-table'>
            <Table className='contracts-table-component' columns={ServiceContractColumns} rowKey='id' dataSource={getDataSource()} pagination={{defaultPageSize:25}} expandable={expandable} rowClassName='contracts-table-row'/>
        </div>
    )
}