import React, {useEffect, useState} from 'react';
import './styles.scss';
import {LineItemColumns} from "./TableConfig";
import {Table} from "antd";
import ContractActions from "../../../actions/ContractActions";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {TContractLineItem} from "../../../stores/App/Types";

interface Props {
    lineItems?:TContractLineItem[];
    serviceContractId:string,
    showEarlyRenewalItems?:boolean,
}

export default (props:Props) => {
    const { lineItems, serviceContractId, showEarlyRenewalItems } = props;
    const [curLineItems, setCurLineItems] = useState([]);
    const [loadingLineItems, setLoadingLineItems] = useState(false);

    useEffect(() => {
        const getContracts = async () => {
            if (serviceContractId) {
                setLoadingLineItems(true)
                let curContracts = await ContractActions.getContractLineItems(serviceContractId);
                setCurLineItems(curContracts);
                setLoadingLineItems(false);
            }
        }
        getContracts();
    },[serviceContractId])

    function getContent(){
        if (loadingLineItems) return <DefaultLoader text='Loading Contract Items'/>;
        else {
            let lineItemsToUse = lineItems || curLineItems;
            if (lineItemsToUse) {
                lineItemsToUse = lineItemsToUse.filter(lineItem => {
                    if (!!lineItem.decommissioned_date){
                        return false;
                    }
                    if (!showEarlyRenewalItems){
                        return !lineItem.renewed
                    } else {
                        return true;
                    }
                })
            }
            return <Table columns={LineItemColumns} dataSource={lineItemsToUse} rowKey='id' pagination={false}/>
        }
    }

    return (
        <div className='nested-contracts'>
            {getContent()}
        </div>
    )
}