import React, {useState} from 'react';
import moment from 'moment';
import cx from 'classnames';
// import {DatePicker} from 'antd';

import './styles.scss';

// const {RangePicker} = DatePicker;

interface TEasyDatePickerDate {
    display:string,
    endDate?:moment.Moment,
    startDate?:moment.Moment,
}

interface Props {
    dates:TEasyDatePickerDate[],
    onChange?:(endDate?:moment.Moment, startDate?:moment.Moment)=>void,
    defaultIndex?:number,
    disabled?:boolean,
}

export default (props:Props) => {
    const { dates, onChange, defaultIndex, disabled } = props;
    const [selected, setSelected] = useState<number|string>(defaultIndex || 0);

    // function onDatePickerChange(dates:any){
    //     if (onChange && dates) {
    //         const [startDate, endDate] = dates;
    //         if (startDate && endDate) {
    //             setSelected('date-picker');
    //             onChange(endDate, startDate);
    //         }
    //     }
    // }

    // function getRanges(){
    //     let ret:any = {};
    //     dates.forEach(date => {
    //         const {display, endDate} = date;
    //         ret[display] = [moment(), endDate];
    //     })
    //     return ret;
    // }

    return (
        <div className='easy-date-picker'>
            {dates.map((date, i) => {
                const {display, endDate, startDate} = date;
                function onClick(){
                    if (disabled) return null;
                    let curStartDate:any = startDate;
                    if (!curStartDate) curStartDate = moment();
                    if (onChange) {
                        setSelected(i);
                        onChange(endDate, curStartDate);
                    }
                }
                let curSelected = i === selected;
                return (
                    <div key={i} className={cx('date', { selected: curSelected, disabled })} onClick={onClick}>
                        {display}
                    </div>
                )
            })}
            {/*<RangePicker onChange={onDatePickerChange} bordered={false} allowClear={false} inputReadOnly*/}
            {/*             ranges={getRanges()}*/}
            {/*            className={cx('custom-date-picker', {selected: selected === 'date-picker'})}/>*/}
        </div>
    )
}