import axios from 'axios';
import {ESearchBy} from "./Types";
import AppStore from "../App/AppStore";
import {TContractFile} from "../App/Types";

export default class AdminActions {
    static async searchAllContracts(value:string, searchBy:ESearchBy){
        let url = `${AppStore.baseUrl}/service-contracts`;
        let params = { [`${searchBy}_contains`]: value };
        try{
            let res = await axios.get(url, {params});
            return res.data;
        } catch (e) {
            console.log('Could not search contracts', e.message);
            return undefined;
        }
    }

    static async getContractFileDownloadUrl(file_id:number){
        let url = `${AppStore.baseUrl}/contract-files/get-file-url`;
        let params = {file_id}
        try{
            let res = await axios.get(url, {params});
            return res.data;
        } catch (e) {
            console.log('Could not get download URL', e.message);
            return undefined;
        }

    }
    static async deleteContractFile(file_id:number){
        let url = `${AppStore.baseUrl}/contract-files/${file_id}`;
        try{
            await axios.delete(url);
            return true;
        } catch (e) {
            console.log('Could not delete file', e.message);
            return false;
        }
    }

    static async uploadContractFiles(files:any, type:string, contract_id:number){
        let url = `${AppStore.baseUrl}/contract-files/upload`;
        let formData = new FormData();
        let data = {type, contract_id};
        formData.append('data', JSON.stringify(data));
        formData.append('files', files[0]);
        try{
            let response = await axios.post<TContractFile>(url, formData);
            return response.data;
        } catch (e) {
            console.log('Could not upload contract file.', e.message);
            return false;
        }
    }
}