export enum ESearchBy {
    Serial = 'serial_number',
    POInvoiceNumber ='customer_po',
    ServiceContractID = 'service_contract_id',
}

export enum EContractFileType {
    Entitlement = 'entitlement',
    PurchaseOrder ='purchase_order',
    Invoice = 'invoice',
}