import React, {Component} from 'react';
import {observer} from "mobx-react";
import {Select} from "antd";

import './styles.scss';
import {TManufacturer} from "../../../stores/App/Types";
import M3Translations from "../../../global/managers/M3Translations";
import DataManipulation from "../../../global/utils/DataManipulation";

const Option = Select.Option;

interface Props {
    manufacturers:TManufacturer[]
    onChange:(manufacturer:any)=>void,
    style?:any;
}

class ManufacturerSelect extends Component<Props>{

    get options(){
        if (!this.props.manufacturers) return null;
        else return this.props.manufacturers
            .slice() // Do this for mobx to update the array in place
            .sort((a, b)=> DataManipulation.compare(M3Translations.translateManufacturer(a.manufacturer), M3Translations.translateManufacturer(b.manufacturer)))
            .map(manufacturer => <Option key={manufacturer.id} value={manufacturer.id}>{M3Translations.translateManufacturer(manufacturer.manufacturer)}</Option>)
    }

    render(){
        const {onChange, style} = this.props;
        return(
            <Select className='manufacturer-select' style={style} size='large' showSearch allowClear
                    placeholder='Filter By Manufacturer' onChange={onChange} optionFilterProp='children'>
                {this.options}
            </Select>
        )
    }
}

export default observer(ManufacturerSelect);