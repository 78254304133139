import React from 'react';
import moment from 'moment';
import {StackedColumnChart} from "@opd/g2plot-react";
import {CSCStackedColumnConfig} from "./CSCConfig";
import {TServiceContract} from "../../../stores/App/Types";

interface Props {
    contracts:TServiceContract[],
}

export default (props:Props) => {
    const { contracts } = props;

    function getStackedColumnConfig(){
        return contracts
            .sort((a, b) => new Date(a.contract_end_date).getTime() - new Date(b.contract_end_date).getTime())
            .map(contract =>{
                let m = moment(contract.contract_end_date);
                let manufacturer = contract.manufacturer?.manufacturer;
                if (manufacturer === 'PPA') manufacturer = 'IBM Software PPA';
                else if (manufacturer === 'VMWare') manufacturer = 'VMware';
                return {Date: m.startOf('month').toISOString(), type:manufacturer, Contracts:contract.contract_line_items};
            })
    }

    return (
        <div className='contracts-stacked-column'>
            <StackedColumnChart {...CSCStackedColumnConfig} data={getStackedColumnConfig()}/>
        </div>
    )
}