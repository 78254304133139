import React, {useEffect, useState} from 'react';
import _ from "lodash";
import moment from 'moment';
import {ClockCircleOutlined, PhoneOutlined} from '@ant-design/icons';
import {Empty, Row, Col, Input, Checkbox, Alert} from 'antd';
import {motion} from 'framer-motion';

import './styles.scss';

import {ContractsTable} from "../../components/specific/ContractsTable/ContractsTable";
import EasyDatePicker from "../../components/specific/EasyDatePicker/EasyDatePicker";
import {ReactComponent as NoData} from '../../assets/undraw/undraw_no_data.svg';
import DefaultLoader from "../../components/common/DefaultLoader/DefaultLoader";
import ContractCount from "../../components/specific/ContractStats/ContractCount";
import ContractsStackedColumn from "../../components/specific/ContractsStackedColumn/ContractsStackedColumn";
import {TServiceContract} from "../../stores/App/Types";
import ManufacturerSelect from "../../components/specific/ManufacturerSelect/ManufacturerSelect";
import AppStore from "../../stores/App/AppStore";
import ManufacturerPartSelect from "../../components/specific/ManufacturerPartSelect/ManufacturerPartSelect";
import AppActions from "../../stores/App/AppActions";
import {observer} from "mobx-react";

export default observer(() => {

    const [contracts, setContracts] = useState<TServiceContract[]>([])
    const [loadingContracts, setLoadingContracts] = useState(false)
    const [endDate, setEndDate] = useState<moment.Moment>()
    const [selectedPartId, setSelectedPartId] = useState();
    const [selectedManufacturerId, setSelectedManufacturerId] = useState();
    const [serialSearch, setSerialSearch] = useState();

    function onDateChange(endDate?: moment.Moment, startDate?: moment.Moment) {
        setEndDate(endDate);
        if (endDate) {
            sendContractRequest({endDate});
        } else {
            sendContractRequest({});
        }
    }

    const datesConfig = [
        {display: '1 Month', endDate: moment().add(1, 'month').add(1, 'day')},
        {display: '3 Months', endDate: moment().add(3, 'month').add(1, 'day')},
        {display: '6 Months', endDate: moment().add(6, 'month').add(1, 'day')},
        {display: '1 Year', endDate: moment().add(1, 'year').add(1, 'day')},
        {display: '3 Years', endDate: moment().add(3, 'year').add(1, 'day')},
        {display: '5 Years', endDate: moment().add(5, 'year').add(1, 'day')},
        {display: 'View All', endDate: undefined},
    ]

    useEffect(() => {
        sendContractRequest({endDate});
        // eslint-disable-next-line
    }, [])


    async function sendContractRequest(obj: any) {
        let curObject = {endDate, serialSearch, manufacturerId: selectedManufacturerId, partId: selectedPartId, ...obj};
        setLoadingContracts(true);
        let contracts = await AppActions.filterContracts(curObject);
        setContracts(contracts);
        setLoadingContracts(false);
    }

    function getContractExpirationCounts() {
        let expiresInOneMonth: TServiceContract[] = [];
        let expiresInThreeMonths: TServiceContract[] = [];
        let expiresInLessThanYear: TServiceContract[] = [];

        getFilteredContracts().forEach(contract => {
            const curEndDate = moment(contract.contract_end_date);
            const curDate = moment()
            let monthsToExpire = curEndDate.diff(curDate, 'months');
            let yearsToExpire = curEndDate.diff(curDate, 'years');

            if (yearsToExpire < 1) expiresInLessThanYear.push(contract);
            if (monthsToExpire < 1) expiresInOneMonth.push(contract)
            if (monthsToExpire < 3) expiresInThreeMonths.push(contract);
        })
        return {expiresInOneMonth, expiresInThreeMonths, expiresInLessThanYear};
    }

    function onManufacturerChange(manId?: number) {
        setSelectedManufacturerId(manId);
        sendContractRequest({manufacturerId: manId});
    }

    function onManufacturerPartChange(partId?: string) {
        setSelectedPartId(partId);
        sendContractRequest({partId: partId});
    }

    let onSearchBySerialDebounce = _.debounce(onSearchBySerial, 500);

    async function onSearchBySerial(value: any) {
        setSerialSearch(value);
        sendContractRequest({serialSearch: value});
    }

    function getContractCounts() {
        const {expiresInOneMonth, expiresInThreeMonths, expiresInLessThanYear} = getContractExpirationCounts();
        let endDateToUse = endDate;
        if (!endDateToUse) endDateToUse = moment();
        let monthDiff = endDateToUse.diff(moment(), 'months');
        let yearDiff = endDateToUse.diff(moment(), 'years');

        let ret: any = {};
        if (monthDiff > 0 || !endDate) ret['expiresInOneMonth'] = expiresInOneMonth;
        if (monthDiff >= 2 || !endDate) ret['expiresInThreeMonths'] = expiresInThreeMonths;
        if (yearDiff >= 1 || !endDate) ret['expiresInLessThanYear'] = expiresInLessThanYear;

        return Object.values(ret).map((value: any, i: number) => {
            let endText = '1 Month';
            if (i === 1) endText = '3 Months';
            if (i === 2) endText = '1 Year';
            return (
                <Col key={i} span={24 / ret.length}>
                    <ContractCount id={i} contracts={value} endText={endText}/>
                </Col>
            )
        })
    }

    function getFilteredContracts() {
        // 333 is Memorial Hermann
        if (AppStore.user?.company.id === 333) {
            return contracts.filter(contract => contract.manufacturer?.manufacturer !== 'APC')
        } else return contracts;
    }

    function onShowRenewalItemsChange(e: any) {
        AppActions.setShowItemRenewals(e.target.checked);
    }

    function getHref() {
        if (!AppStore.salesRep || !AppStore.user) {
            return undefined;
        }

        let base = `mailto:${AppStore.salesRep?.email}`;
        let body = `This is ${AppStore.user?.first_name} with ${AppStore.user?.company.company_name}. 
        \n\nI would like to move the attached maintenance contracts to Mark III Systems:`;

        let subj = `Move ${AppStore.user?.company.company_name} Contracts to Mark III`;
        let full = `${base}?subject=${subj}&body=${body}`;
        return encodeURI(full);
    }


    function getContent() {
        if (loadingContracts) return <DefaultLoader text='Loading Contract Data'/>;
        else if (contracts && contracts.length > 0) {
            return (
                <div className='contracts-table-wrapper'>
                    <Row gutter={24} className='row'>
                        {getContractCounts()}
                    </Row>
                    <Row gutter={24} className='row'>
                        <Col span={24}>
                            <motion.div animate={{opacity: 1, y: 0}}
                                        initial={{opacity: 0, y: 25}}
                                        transition={{type: 'spring'}} className='stacked-column-wrapper'>
                                <ContractsStackedColumn contracts={getFilteredContracts()}/>
                            </motion.div>
                        </Col>
                    </Row>
                    <Row gutter={24} className='row'>
                        <Col span={24}>
                            <motion.div animate={{opacity: 1, y: 0}}
                                        initial={{opacity: 0, y: 25}}
                                        transition={{delay: 0.2, type: 'spring'}}>
                                <Alert style={{width: '100%'}} type='info'
                                       message='Want to move your other maintenance contracts to Mark III?'
                                       description={
                                           <div className='alert-content'>
                                               <div>Request a maintenance quote and move your maintenance contracts to
                                                   Mark III. This will allow Mark III and Tracker to allow you to track
                                                   them and set up alerts when they're close to expiration.
                                               </div>
                                               <a className='move-contracts-button' href={getHref()}>Request Quote</a>
                                           </div>}
                                />
                            </motion.div>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <motion.div animate={{opacity: 1, y: 0}}
                                        initial={{opacity: 0, y: 25}}
                                        transition={{delay: 0.4, type: 'spring'}}>
                                <ContractsTable contracts={getFilteredContracts()}
                                                showEarlyRenewalItems={AppStore.showEarlyRenewalItems}/>
                            </motion.div>
                        </Col>
                    </Row>
                </div>
            )
        } else return <Empty image={<NoData/>} description='Whoops! No Contracts found with the specified parameters.'/>
    }

    return (
        <div className='dashboard'>

            {!AppStore.user?.company &&
            <div className='no-company'>
                <Alert style={{width: '100%'}} type='warning' message='Your account is pending review'
                       description={`Welcome to Tracker, ${AppStore.user?.first_name}! Your account is currently pending review with your Mark III team. Once approved, you’ll see all the maintenance contracts and assets through Mark III associated with your organization below. Thanks for your patience!`}/>
            </div>}

            <div className='dashboard-content'>
                <div className='left'>
                    <div className='date-picker'>
                        <div className='header'><ClockCircleOutlined/>Expiration in:</div>
                        <EasyDatePicker defaultIndex={6} dates={datesConfig} onChange={onDateChange}
                                        disabled={loadingContracts}/>
                        {loadingContracts &&
                        <motion.div animate={{opacity: 1, y: 0}}
                                    initial={{opacity: 0, y: 25}}
                                    transition={{delay: 0.2, type: 'spring'}}>
                            <Alert style={{width: '100%'}} type='info'
                                   message="Why can't I change dates?" description={
                                <div className='alert-content'>
                                    <div>Due to the large volume of contracts, you must wait until the current view has
                                        loaded all of it contracts before you changing to a new view.
                                    </div>
                                    {/*<a className='move-contracts-button' href={getHref()}>Request Quote</a>*/}
                                </div>}
                            />
                        </motion.div>}
                    </div>
                    <ManufacturerSelect style={{margin: '12px 0'}} manufacturers={AppStore.manufacturers}
                                        onChange={onManufacturerChange}/>
                    <ManufacturerPartSelect onChange={onManufacturerPartChange}/>
                    <Input className='search' placeholder='Filter By Serial' size='large'
                           onChange={e => onSearchBySerialDebounce(e.target.value)}/>
                    <Checkbox defaultChecked={AppStore.showEarlyRenewalItems} className='show-early-renewals'
                              onChange={onShowRenewalItemsChange}>Show Early Renewal Items</Checkbox>
                    {AppStore.salesRep &&
                    <div className='sales-rep-card'>
                        <div className='sales-rep-card-header'><PhoneOutlined style={{fontSize: 18, marginRight: 6}}/>Mark
                            III Client Leader
                        </div>
                        <div
                            className='sales-rep-card-item'>{AppStore.salesRep.first_name} {AppStore.salesRep.last_name}</div>
                        <a href={`mailto:${AppStore.salesRep.email}`}>{AppStore.salesRep.email}</a>
                        <div className='sales-rep-card-item'>{AppStore.salesRep.phone_number}</div>
                    </div>}
                </div>
                <div className='right'>
                    {getContent()}
                </div>
            </div>
        </div>
    )
})