import React from 'react';
import {
    CheckCircleOutlined, ExclamationCircleOutlined,
    FieldTimeOutlined, ProfileOutlined, DollarOutlined, FileProtectOutlined
} from '@ant-design/icons';
import cx from 'classnames';
import {Tooltip} from "antd";
import moment from 'moment';

import './styles.scss';

import {StringUtils} from "../../../global/utils/string";
import {
    TContractFile, TContractLineItem,
    TManufacturerPart,
    TPartDescription,
    TReminder,
    TServiceContract
} from "../../../stores/App/Types";
import {EContractFileType} from "../../../stores/Admin/Types";
import AdminActions from "../../../stores/Admin/AdminActions";
import {BrowserUtils} from "../../../global/utils/Browser";
import RemindersModalAsButton from "../RemindersForm/RemindersModalAsButton";
import NotesModalAsButton from "../NotesForm/NotesModalAsButton";

export const ServiceContractColumns = [
    {
        title: 'Manufacturer', dataIndex: 'manufacturer', key: 'manufacturer',
        render: (manufacturer: any) => {
            let manufacturerShow = manufacturer?.manufacturer;
            if (manufacturerShow === 'PPA') manufacturerShow = 'IBM Software PPA'
            else if (manufacturerShow === 'VMWare') manufacturerShow = 'VMware';
            return <div className='manufacturer'>{manufacturerShow || 'N/A'}</div>
        },
    },
    {
        title: 'Expiration', dataIndex: 'contract_end_date', key: 'contract_end_date',
        sortDirections: ['descend'] as any,
        sorter: (a: any, b: any) => {
            const {contract_end_date: endDateA} = a;
            const {contract_end_date: endDateB} = b;
            return new Date(endDateA).getTime() - new Date(endDateB).getTime()
        },
        render: (date: string) => {
            if (!date) return <div className='end-date'>No End Recorded</div>

            let icon = <CheckCircleOutlined/>;
            let color = 'green';


            let endDate = moment(date);
            let curDate = moment();
            let monthsToExpire = endDate.diff(curDate, 'months');
            let yearsToExpire = endDate.diff(curDate, 'years');

            let expiresAt = `${monthsToExpire} ${StringUtils.addMultipleToNoun(monthsToExpire, 'month')}`;

            if (yearsToExpire > 0) {
                let months = Math.round(monthsToExpire - (yearsToExpire * 12));
                expiresAt = `${yearsToExpire} ${StringUtils.addMultipleToNoun(yearsToExpire, 'year')}, ${months} ${StringUtils.addMultipleToNoun(months, 'month')}`
            }
            if (monthsToExpire < 1) {
                icon = <ExclamationCircleOutlined/>
                color = 'red';
            } else if (monthsToExpire < 3) {
                icon = <FieldTimeOutlined/>
                color = 'orange';
            }

            return (
                <div className={cx('end-date', color)}>
                    <Tooltip title={`Expires in ${expiresAt}`} placement='top'>
                        <div>{icon} {endDate.utc().format('MMM DD, YYYY')}</div>
                    </Tooltip>
                </div>
            )
        },
    },
    {title: 'PO', dataIndex: 'po_invoice_number', key: 'po_invoice_number'},
    // {title: 'Sales Type', dataIndex:'sales_type', key:'sales_type'},
    {title: 'Items', dataIndex: 'contract_line_items', key: 'contract_line_items'},
    {title: 'Customer #', dataIndex: 'inst_cust_number', key: 'inst_cust_number'},
    {
        title: 'Documents', dataIndex: 'contract_files', key: 'entitlement',
        render: (contract_files: TContractFile[]) => {
            let iconMap = {
                [EContractFileType.Entitlement]: {
                    icon: FileProtectOutlined,
                    tooltip: 'Download Entitlement'
                },
                [EContractFileType.PurchaseOrder]: {
                    icon: ProfileOutlined,
                    tooltip: 'Download Purchase Order'
                },
                [EContractFileType.Invoice]: {
                    icon: DollarOutlined,
                    tooltip: 'Download Invoice',
                },
            }
            return contract_files.map(file => {
                async function downloadFile(e: any) {
                    e.stopPropagation();
                    let url = await AdminActions.getContractFileDownloadUrl(file.id);
                    BrowserUtils.downloadFile(file.document_name, url);
                }

                let Icon = iconMap[file.type].icon;
                let tooltip = iconMap[file.type].tooltip;
                return (
                    <Tooltip key={file.id} title={tooltip}>
                        <Icon onClick={downloadFile} style={{fontSize: 22}}/>
                    </Tooltip>
                )
            })
        }
    },
    {
        title: 'Reminders',
        dataIndex: 'reminders',
        key: 'reminders',
        render: (reminders: TReminder[], record: TServiceContract) => {
            return <RemindersModalAsButton serviceContract={record}/>
        }
    },
];

export const LineItemColumns = [
    {
        title: 'Part', dataIndex: 'manufacturer_part', key: 'manufacturer_part',
        render: (manufacturerPart: TManufacturerPart) => {
            return <div className='manufacturer'>{manufacturerPart?.manufacturer_part_name || 'N/A'}</div>
        }
    },
    {
        title: 'Part #', dataIndex: 'manufacturer_part', key: 'manufacturer_part_number',
        render: (manufacturerPart: TManufacturerPart) => {
            return <div className='manufacturer'>{manufacturerPart?.hw_sw_reference || 'N/A'}</div>
        }
    },
    {
        title: 'Description', dataIndex: 'part_description', key: 'part_description',
        render: (partDescription: TPartDescription) => {
            return <div className='manufacturer'>{partDescription?.part_description || 'N/A'}</div>
        }
    },
    {
        title: 'Serial', dataIndex: 'serial_number', key: 'serial_number',
        render: (serial_number: string) => {
            return <div className='manufacturer'>{serial_number || 'N/A'}</div>
        }
    },
    {title: 'Quantity', dataIndex: 'quantity', key: 'quantity'},
    {
        title: 'EoS Availability', dataIndex: 'manufacturer_part', key: 'manufacturer_part_eol',
        render: (manufacturerPart: TManufacturerPart) => {
            let eol;
            if (manufacturerPart?.end_of_life_date) eol = moment(manufacturerPart?.end_of_life_date).format('MMM DD, YYYY');
            return <div className='manufacturer'>{eol || 'N/A'}</div>
        }
    },
    {
        title: 'Comments', dataIndex: 'comments', key: 'comments',
    },
    {
        title: 'Notes', key: 'notes', render: (contractLineItem:TContractLineItem) => {
            return <NotesModalAsButton contractLineItem={contractLineItem}/>
        }
    },
    {
        title: 'Renewed', dataIndex: 'renewed', key: 'renewed', render: (renewed: boolean) => {
            if (renewed) return <CheckCircleOutlined style={{color: "#4caf50", fontSize: 22}}/>
        }
    },
]