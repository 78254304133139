import React from 'react';
import { Route } from "react-router-dom";
import { LayoutOutlined } from '@ant-design/icons';

import { BrowserRoutes } from "../../../stores/App/BrowserRouter";
import Dashboard from "../../../containers/Dashboard/Dashboard";
import Admin from "../../../containers/Admin/Admin";


export const AppRoutesObject = {
    'Admin': {
        render: <Route key={1} exact path={BrowserRoutes.admin} component={Admin}/>,
        path: BrowserRoutes.admin,
        icon: <LayoutOutlined/>
    },
    'Dashboard': {
        render: <Route key={0} exact path={BrowserRoutes.home + '/'} component={Dashboard}/>,
        path: BrowserRoutes.home,
        icon: <LayoutOutlined/>
    },
};