import React, {useState} from 'react';
import {Col, Dropdown, Menu, Row} from "antd";
import {LoadingOutlined, CloudDownloadOutlined, DeleteOutlined, MoreOutlined} from '@ant-design/icons';

import './styles.scss'

import {TContractFile} from "../../../stores/App/Types";
import moment from "moment";
import AdminActions from "../../../stores/Admin/AdminActions";
import {BrowserUtils} from "../../../global/utils/Browser";

interface Props {
    file:TContractFile,
    onDelete:(id:number)=>void,
}

export default (props:Props) => {
    const {document_name, created_at, id} = props.file;

    const [loading, setLoading] = useState(false);

    async function downloadFile(){
        if (loading) return undefined;
        setLoading(true);

        let url = await AdminActions.getContractFileDownloadUrl(id)
        BrowserUtils.downloadFile(document_name, url);

        setLoading(false);
    }
    async function deleteFile(e:any){
        if (loading) return undefined;
        setLoading(true);

        let success = await AdminActions.deleteContractFile(id);
        if (success) props.onDelete(id)

        setLoading(false);
    }

    const deleteIconProps = {style:{fontSize: 20, color: "#ef7a49"}}
    const deleteIcon = loading ? <LoadingOutlined {...deleteIconProps} spin/> : <DeleteOutlined {...deleteIconProps}/>

    const moreMenu = (
        <Menu>
            <Menu.Item className='files-download menu-item' onClick={downloadFile}>
                <CloudDownloadOutlined style={{fontSize: 20, marginRight: 12}}/>
                Download File
            </Menu.Item>
            <Menu.Item className='menu-item' onClick={deleteFile}>
                {deleteIcon}
                Delete File
            </Menu.Item>
        </Menu>
    )
    return (
        <Row className='single-file'>
            <Col span={14} className='col'>
                <div className='details'>
                    <div className='file-name'>
                        {document_name}
                    </div>
                    <div className='file-uploaded-at'>
                        {moment(created_at).format('hh:mm A - MM/DD/YY')}
                    </div>
                </div>
            </Col>
            <Col span={10} className='col end desktop-scaled'>
                <Dropdown overlay={moreMenu} trigger={['click']}
                          overlayClassName='files-single-dropdown'>
                    <MoreOutlined style={{fontSize: 20}}/>
                </Dropdown>
            </Col>
        </Row>
    )
}