import React, {useState} from 'react';
import {Select, Empty} from "antd";
import _ from 'lodash';

import './styles.scss';
import {TManufacturerPart} from "../../../stores/App/Types";
import AppActions from "../../../stores/App/AppActions";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";

const Option = Select.Option;

interface Props {
    onChange:(manufacturer:any)=>void,
    style?:any;
}

export default (props:Props) => {
    const {onChange, style} = props;
    const [manufacturerParts, setManufacturerParts] = useState<TManufacturerPart[]>([]);
    const [loadingParts, setLoadingParts] = useState(false);

    function renderOptions(){
        return manufacturerParts.map(manufacturerPart => <Option key={manufacturerPart.manufacturer_part_name} value={manufacturerPart.manufacturer_part_name}>{manufacturerPart.manufacturer_part_name}</Option>)
    }

    let onSearchDebounce = _.debounce(onSearch, 500);

    async function onSearch(value:string){
        if (!value || value === '') return null;

        setLoadingParts(true);

        let parts = await AppActions.getManufacturerParts(value);
        setManufacturerParts(parts);

        setLoadingParts(false);
    }

    function getContent(){
        if (loadingParts) return <DefaultLoader text='Loading Manufacturer Parts'/>;
        else if (manufacturerParts.length === 0) return <Empty description='No Manufacturer Parts Found'/>
    }


    return (
        <Select className='manufacturer-part-select' size='large' showSearch style={style}
                placeholder='Filter By Part' onSearch={onSearchDebounce} onChange={onChange}
                optionFilterProp='children' notFoundContent={getContent()} allowClear>
            {renderOptions()}
        </Select>
    )
}