import {EContractFileType} from "../Admin/Types";
import moment from 'moment';
export interface TUser {
    activated:number,
    blocked:boolean|null,
    company:TCompany,
    email:string,
    first_name:string,
    id:number,
    last_name:string,
    role:TUserRole,
    user_group:number,
    username:string,

}

export interface TSalesRep {
    email:string,
    first_name:string,
    id:number,
    last_name:string,
    phone_number:string,
}

export interface TSignupValues {
    username?:string,
    first_name:string,
    last_name:string,
    email:string,
    password:string,
}

export enum EReminderInterval {
    OneMonth = 'one_month',
    ThreeMonths = 'three_months',
    TwoWeeks = 'two_weeks',
}

export const IntervalToMilliseconds = {
    [EReminderInterval.OneMonth]: moment.duration(1, 'months').asMilliseconds(),
    [EReminderInterval.ThreeMonths]: moment.duration(3, 'months').asMilliseconds(),
    [EReminderInterval.TwoWeeks]: moment.duration(2, 'weeks').asMilliseconds(),
}

export interface TReminder {
    id:number,
    company:TCompany,
    service_contract:TServiceContract,
    date:string,
    emails:string,
    reminder_interval:EReminderInterval,
}

export interface TUserRole {
    description:string,
    id:number,
    name:string,
    type:string,
}

export interface TLoginValues {
    username:string,
    password:string,
}

export interface TLoginResponse {
    jwt:string,
    user:TUser,
}

export interface TSignupResponse {
    jwt:string,
    user:TUser,
}

export interface TManufacturer {
    manufacturer:string,
    id:number,
}

export interface TManufacturerPart {
    id:number,
    hw_sw_reference:string,
    manufacturer_part_name:string,
    end_of_life_date:string,
}

export interface TPartDescription{
    id:number,
    official_part_number:string,
    part_description:string,
}

export interface TContractLineItem {
    billing_end_date:string,
    billing_start_date:string,
    comments:string,
    contract_line_item:string,
    decommissioned_date:string,
    end_of_life_date:string,
    id:number,
    line_item_number:string,
    manufacturer?:TManufacturer,
    manufacturer_part:TManufacturerPart,
    part_description?:TPartDescription,
    quantity:number,
    serial_number:string,
    service_contract:number, // Foreign key of TServiceContract
    renewed?:boolean,
    notes:string,
}

export interface TCompany {
    id:number,
    company_name:string,
    tax_comment:string,
    tax_rate:string,
    tax_exempt:boolean,
    sales_rep?:number,
}

export interface TServiceContract {
    bill_date:string,
    billing_code:string,
    comments:string,
    company:TCompany, //Company ID
    contract_end_date:string,
    contract_line_items:number,
    contract_number:string,
    contract_start_date:string,
    customer_po:string,
    decommissioned_date:string,
    id:number,
    invoice_number:string,
    po_invoice_number:string,
    sales_rep:string,
    sales_type:string,
    service_contract_id:string,
    vendor?:TVendor,
    manufacturer?:TManufacturer,
    contract_files:TContractFile[],
    reminders:TReminder[],
}

export interface TContractFile {
    document_name:string,
    service_contract:number,
    service_contract_id:string, // Canonical contract ID from the imported CSV
    type:EContractFileType
    id:number,
    path:string,
    created_at:string,
}

export interface TVendor {
    id:number,
    vendor:string,
}

export const LSAuthTokenKey = 'm3_jwt';