import React, {useState} from 'react';
import {Tooltip, Modal, Badge} from "antd";
import {BellOutlined} from '@ant-design/icons';

import './styles.scss';

import RemindersForm from "./RemindersForm";
import {TServiceContract} from "../../../stores/App/Types";
import ContractActions from "../../../actions/ContractActions";

interface Props {
    serviceContract:TServiceContract,
}

export default (props:Props) => {
    const {serviceContract} = props;
    const [showForm, setShowForm] = useState(false);
    const [reminders, setReminders] = useState(serviceContract.reminders);

    async function getReminders(){
        let reminders = await ContractActions.getReminders(serviceContract.id);
        setReminders(reminders);
    }

    const onBellClick = async (e:any) => {
        e.stopPropagation();
        await getReminders()
        setShowForm(true);
    }

    const onCancel = () => {
        setShowForm(false);
    }

    const onFormSubmit = async (values:any) => {

       await ContractActions.syncReminders(reminders, values, serviceContract);
       await getReminders()
       setShowForm(false);
    }

    if (!serviceContract.contract_end_date) return (
        <div>
            Cannot set reminders because there is no Contract End Date
        </div>
    )

    return(
        <div onClick={e => e.stopPropagation()}>
            <Tooltip title='Set Reminders'>
                <Badge count={reminders?.length || 0} style={{ backgroundColor: '#4caf50' }} size='small'>
                    <BellOutlined onClick={onBellClick} style={{fontSize: 22, color: reminders && reminders.length > 0 ? '#4caf50' : 'gray'}}/>
                </Badge>
            </Tooltip>
            <Modal centered title='Set Reminders' visible={showForm} destroyOnClose onCancel={onCancel} footer={null}>
                <RemindersForm onSubmit={onFormSubmit} reminders={reminders}/>
            </Modal>
        </div>
    )
}