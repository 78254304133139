const ManufacturerTranslations:any = {
    'PPA':'IBM Software PPA',
    'VMWare':'VMware',
}

export default class M3Translations {

    static translateManufacturer(manufacturerName?:string){
        if (manufacturerName && ManufacturerTranslations[manufacturerName]) return ManufacturerTranslations[manufacturerName];
        else return manufacturerName;
    }

}