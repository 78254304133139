import React, {useEffect, useState} from 'react';
import {Descriptions} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import moment from "moment";

import './styles.scss';

import {TContractFile, TServiceContract} from "../../../stores/App/Types";
import Button from '../../common/Button/Button';
import UploadContractFile from "../UploadContractFile/UploadContractFile";
import SingleContractFile from "../SingleContractFile/SingleContractFile";

interface Props {
    contract:TServiceContract,
}

export default (props:Props) => {
    const {id, service_contract_id, sales_rep, sales_type, contract_end_date, contract_start_date,
        po_invoice_number, contract_number, customer_po, invoice_number, company, contract_files} = props.contract;

    useEffect(() => {

    }, [props.contract.contract_files])

    const [uploadVisible, setUploadVisible] = useState(false);
    const [contractFiles, setContractFiles] = useState(contract_files);

    function onDelete(contractFileId:number){
        let newFiles = contractFiles.filter(file => file.id !== contractFileId);
        setContractFiles(newFiles);
    }
    function onUpload(contractFile:TContractFile){
        let files = [...contractFiles, contractFile];
        setContractFiles(files);
    }

    return (
        <div className='contract-result'>
            <Descriptions className='description' title='Contract Details' layout='vertical'>
                <Descriptions.Item label='Company'>
                    {company.company_name}
                </Descriptions.Item>
                <Descriptions.Item label='Tracker Contract ID'>
                    {id}
                </Descriptions.Item>
                <Descriptions.Item label='M3 Internal Service Contract ID'>
                    {service_contract_id}
                </Descriptions.Item>
                <Descriptions.Item label='Sales Rep'>
                    {sales_rep}
                </Descriptions.Item>
                <Descriptions.Item label='Contract Number'>
                    {contract_number}
                </Descriptions.Item>
                <Descriptions.Item label='PO Invoice Number'>
                    {po_invoice_number}
                </Descriptions.Item>
                <Descriptions.Item label='Sales Type'>
                    {sales_type}
                </Descriptions.Item>
                <Descriptions.Item label='Mark III PO'>
                    {customer_po}
                </Descriptions.Item>
                <Descriptions.Item label='Invoice Number'>
                    {invoice_number}
                </Descriptions.Item>
                <Descriptions.Item label='Contract Start Date'>
                    {contract_start_date ? moment(contract_start_date).format('MMM DD, YYYY') : "None"}
                </Descriptions.Item>
                <Descriptions.Item label='Contract End Date'>
                    {contract_end_date ? moment(contract_end_date).format('MMM DD, YYYY') : "None"}
                </Descriptions.Item>
            </Descriptions>
            <div className='uploads-container'>
                <div className='uploads-container-title'>
                    <div>Contract Files</div>
                    <Button icon={<UploadOutlined/>} onClick={()=>setUploadVisible(true)}>Upload File</Button>
                </div>

                <div className='uploads-container-content'>
                    {contractFiles && contractFiles.map(file => <SingleContractFile key={file.id} file={file} onDelete={onDelete}/>)}
                </div>
            </div>
            <UploadContractFile visible={uploadVisible} onUpload={onUpload} contract={props.contract} onClose={()=>setUploadVisible(false)}/>
        </div>
    )
}