import React, {useState} from 'react';
import {Modal} from "antd";
import {UploadChangeParam} from "antd/lib/upload";
import {UploadFile} from "antd/es/upload/interface";
import {InboxOutlined, CloseOutlined} from '@ant-design/icons';

import './styles.scss';

import Button from "../../common/Button/Button";
import WDragger from "../../common/WDragger/WDragger";
import AdminActions from "../../../stores/Admin/AdminActions";
import {TContractFile, TServiceContract} from "../../../stores/App/Types";
import {EContractFileType} from "../../../stores/Admin/Types";
import ContractFileTypeSelect from "../ContractFileTypeSelect/ContractFileTypeSelect";

interface Props {
    contract:TServiceContract
    visible?:boolean,
    onClose:()=>void,
    onUpload:(file:TContractFile)=>void,
}

export default (props:Props) => {

    const {po_invoice_number, id} = props.contract;

    const [files, setFiles] = useState<any[]>([]);
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [fileType, setFileType] = useState(EContractFileType.Entitlement);

    const onDeleteInMemFile = (index:number) => {
        let copy = [...files]
        copy.splice(index, 1);
        setFiles(copy);
    }

    const onUploadFiles = async (e: any) => {
        e.stopPropagation();
        setUploadingFiles(true);
        if (files) {
            let curFiles = files.map((fileObj:any) => fileObj.originFileObj);
            let contractFile = await AdminActions.uploadContractFiles(curFiles, fileType, id);
            if (contractFile) {
                setFiles([]);
                props.onUpload(contractFile);
            }
        }
        setUploadingFiles(false);
        props.onClose();
    };

    const onUploadChange = (info: UploadChangeParam) => {
        const {fileList} = info;
        setFiles(fileList);
    }
    const handleCancel = (e:any) => {
        e.stopPropagation();
        props.onClose();
    }
    function renderFiles(){
        if (files && files.length > 0){
            return (
                <div className='files'>
                    {files.map((file:UploadFile, i:number) => {
                        return (
                            <div key={i} className='file'>
                                <div className='file-name'>{file.name}</div>
                                <CloseOutlined type='close' onClick={()=>onDeleteInMemFile(i)}/>
                            </div>
                        )
                    })}
                </div>
            )
        } else return null;
    }

    function renderContent(){
        if (files && files.length > 0) return renderFiles();
        else return (
            <WDragger customRequest={() => null} showUploadList={false} onChange={onUploadChange}
                      className='custom-upload' fileList={files}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag a file into this area</p>
            </WDragger>
        )
    }

    return (
        <Modal visible={props.visible}
               title={`Upload Contract Files to ${po_invoice_number}`}
               bodyStyle={{padding: 24}}
               className='upload-contract-file'
               centered
               onOk={onUploadFiles}
               onCancel={handleCancel}
               destroyOnClose
               footer={
                   <div className='upload-contract-file-modal-footer'>
                       <Button key="back" color='clear' onClick={handleCancel}>
                           Cancel
                       </Button>
                       <Button key="submit" enabled={!uploadingFiles && files.length !== 0}
                               loading={uploadingFiles} onClick={onUploadFiles}>
                           Upload File
                       </Button>
                   </div>
               }
        >
            {renderContent()}
            <ContractFileTypeSelect className='type-select' onSelect={(type:any) => setFileType(type)}/>
        </Modal>
    )
}
