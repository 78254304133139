import axios from 'axios';
import qs from 'qs';
import AppStore from "../stores/App/AppStore";
import {EReminderInterval, IntervalToMilliseconds, TReminder, TServiceContract} from "../stores/App/Types";
import moment from 'moment';
import _ from 'lodash';

export default class ContractActions {

    static async getContractLineItems(service_contract_id:string, limit?:number){
        let url = `${AppStore.baseUrl}/contract-line-items`;
        try{
            let params:any = { service_contract_eq:service_contract_id }
            if (limit) params._limit = limit;
            let res = await axios.get(url, {params});
            return res.data;
        }catch (e) {
            console.log('Could not get contracts', e.message);
            return undefined;
        }
    }
    static async updateNotes(contract_line_item_id:number, notes:string){
        let url = `${AppStore.baseUrl}/contract-line-items/update-notes/${contract_line_item_id}`;
        try{
            let res = await axios.put(url, {notes});
            return res.data;
        }catch (e) {
            console.log('Could not get contracts', e.message);
            return undefined;
        }
    }
    static async getServiceContracts(endDate:string, manufacturer?:string){
        let url = `${AppStore.baseUrl}/service-contracts`;
        try{
            const query = qs.stringify({
                _where:[{contract_end_date_gte:new Date().toISOString()}, {contract_end_date_lte:endDate}]
            })
            url = url + '?' + query;
            let res = await axios.get(url);
            return res.data;
        }catch (e) {
            console.log('Could not get contracts', e.message);
            return undefined;
        }
    }

    static async getReminders(service_contract_id:number){
        let url = `${AppStore.baseUrl}/reminders`;
        let params = { 'service_contract.id': service_contract_id };
        try{
            let res = await axios.get(url, {params});
            return res.data;
        } catch (e) {
            console.log('Could not return reminders', e.message);
            return undefined;
        }
    }

    static async syncReminders(curReminders:TReminder[], values:any, serviceContract:TServiceContract){
        if (!serviceContract.contract_end_date) return null;

        const {emails:emailsForm, intervals} = values;
        let emails = emailsForm.map((obj:any) => obj.email)
        let emailsAreEqual = true;
        if (emails && curReminders?.[0]?.emails){
            emailsAreEqual = _.isEqual(emails.sort(), curReminders[0].emails.split(',').sort())
        }

        let curReminderMap:any = {};
        curReminders.forEach(reminder => curReminderMap[reminder.reminder_interval] = reminder);

        let incomingReminderMap:any = {}
        intervals.forEach((interval:EReminderInterval) => {
            let date = moment(new Date(serviceContract.contract_end_date).getTime() - IntervalToMilliseconds[interval])

            incomingReminderMap[interval] = {
                emails:emails.join(','), reminder_interval: interval, date:date.toISOString(),
                service_contract: serviceContract.id, company: serviceContract.company.id
            }
        });

        let added:TReminder[] = [];
        let deleted:TReminder[] = [];
        let updated:TReminder[] = [];

        Object.values(EReminderInterval).forEach(interval => {
            let curReminder = curReminderMap[interval];
            let incomingReminder = incomingReminderMap[interval];

            if (curReminder && !incomingReminder) deleted.push(curReminder);
            else if (incomingReminder && !curReminder) added.push(incomingReminder)
            else if (incomingReminder && curReminder && !emailsAreEqual) updated.push({...curReminder, ...incomingReminder});
        })

        let url = `${AppStore.baseUrl}/reminders`;
        for (let i = 0; i < added.length; i++) {
            const addedElement = added[i];
            await axios.post(url, {...addedElement});
        }

        for (let i = 0; i < deleted.length; i++) {
            const deletedElement = deleted[i];
            await axios.delete(`${url}/${deletedElement.id}`)
        }

        for (let i = 0; i < updated.length; i++) {
            const updatedElement = updated[i];
            await axios.put(`${url}/${updatedElement.id}`, updatedElement);
        }
    }
}