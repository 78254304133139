import React, {Component} from 'react';
import {Select} from 'antd';

import './styles.scss';
import { StringUtils } from '../../../global/utils/string';
import {EContractFileType} from "../../../stores/Admin/Types";

const {Option} = Select;

interface Props {
    onSelect?:(type:string)=>void,
    loading?:boolean,
    className?:string,
    option?:EContractFileType
}

class ContractFileTypeSelect extends Component<Props, any> {
    render() {
         const {className, onSelect, loading, option} = this.props;
        return (
            <Select className={className} size='large' optionLabelProp='children'
                    defaultValue={option || EContractFileType.Entitlement} onSelect={onSelect as any} loading={loading}
                    disabled={loading}>
                {Object.keys(EContractFileType).map(key => <Option value={(EContractFileType as any)[key]}
                                                           key={(EContractFileType as any)[key]}>{StringUtils.camelCaseToRegular((EContractFileType as any)[key], false, true)}</Option>)}
            </Select>
        )
    }
}

export default ContractFileTypeSelect;