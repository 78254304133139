import React from 'react';
import {motion} from 'framer-motion';

import FullPagePng from './images/Hero.png';
import SendReminders from './images/undraw_events.svg';
import Reminders from './images/Reminders.png';
import Filters from './images/Filters.png';
import AndroidIOS from './images/Mobile.png';
import LogoList from './images/Logo-list.png';
import Mobile from './images/undraw_Mobile_app.svg';
import Search from './images/undraw_file_searching.svg';
import Free from './images/free.png';
import './css/webflow.scss';
import './css/splash-saas-website-template.webflow.scss';
import './css/normalize.scss';
import './styles.scss';
import BrowserRouter, {BrowserRoutes} from "../../stores/App/BrowserRouter";
import Logo from "../../assets/logo.png";

export default () => {
    return (
        <div className='marketing'>
            <div data-collapse="medium" data-animation="default" data-duration="400" role="banner"
                 className="navbar w-nav" style={{padding:'12px 0'}}>
                <div className="navigation-div">
                    <div className='app-header-left'>
                        <div className='logo' onClick={() => BrowserRouter.push(BrowserRoutes.home)}>
                            <img alt="Logo" src={Logo}/>
                            <div className='logo-text'>
                                <div className='logo-text-top'>Mark III Systems</div>
                                <div className='logo-text-bottom'>Tracker</div>
                            </div>
                        </div>
                    </div>
                    <nav role="navigation" className="nav-menu-dropdown w-clearfix w-nav-menu">
                        <div onClick={() => BrowserRouter.push(BrowserRoutes.signup)}
                           className="button no-max-width w-button">Sign Up</div>
                        <div onClick={() => BrowserRouter.push(BrowserRoutes.login)}
                           className="button no-max-width w-button">Account Login</div>
                    </nav>
                    <div className="menu-button-green w-nav-button">
                        <div className="icon-2 w-icon-nav-menu"></div>
                    </div>
                </div>
            </div>
            <div className="section-standard hero home">
                <div className="div-standard flex home">
                    <div className="div-560w">
                        <motion.h1 animate={{opacity: 1, y: 0}}
                                   initial={{opacity: 0, y: 25}}
                                   transition={{type: 'spring'}} className="heading-1">
                            Ditch your mountain of IT maintenance spreadsheets
                        </motion.h1>
                        <motion.p animate={{opacity: 1, y: 0}}
                                  initial={{opacity: 0, y: 25}}
                                  transition={{delay: 0.1, type: 'spring'}} className="subheadline">
                            Save hours of valuable time and let Tracker do the hard work of tracking your IT maintenance contracts across 100+ manufacturers and OEMs
                        </motion.p>
                        <motion.div animate={{opacity: 1, y: 0}}
                                    initial={{opacity: 0, y: 25}}
                                    transition={{delay: 0.2, type: 'spring'}}>

                            <div onClick={()=>BrowserRouter.push(BrowserRoutes.signup)} className="button w-button" style={{marginLeft:0}}>Sign Up Now</div>
                        </motion.div>
                    </div>
                    <motion.img animate={{opacity: 1, y: 0}}
                                initial={{opacity: 0, y: 25}}
                                transition={{delay: 0.3, type: 'spring'}} src={FullPagePng} alt=""
                                className="image-101"/>
                </div>
            </div>
            <div className="section-standard blue-background">
                <div className="div-standard">
                    <h2 className="heading-2 centre _800w">Find your contracts instantly. Never miss a renewal.</h2>
                    <div className="div-standard justify-spaced _1140 _60-top-padding">
                        <div className="_350-width">
                            <a href="#send-reminders" className="link-block---persona w-inline-block" style={{height:'100%'}}>
                                <div className="persona" style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}><img src={SendReminders} height={200} alt=""
                                                              className="icon centre-mobile"/>
                                    <h5 className="heading-5 centre-mobile">Reminders</h5>
                                    <p className="paragraph _15px centre-mobile">Avoid surprises with reminders for you and your team</p>
                                    <div className="centre-mobile" style={{marginTop:'auto'}}>
                                        <p className="button w-button" style={{marginLeft: 0, marginRight:'auto'}}>Learn more</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="_350-width">
                            <a href="#search-information" className="link-block---persona w-inline-block" style={{height:'100%'}}>
                                <div className="persona" style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}><img src={Search} height={200} alt=""
                                                              className="icon centre-mobile"/>
                                    <h5 className="heading-5 centre-mobile">Search & Filter</h5>
                                    <p className="paragraph _15px centre-mobile">Search and filter all your IT maintenance contracts</p>
                                    <div className="centre-mobile" style={{marginTop:'auto'}}>
                                        <p className="button w-button" style={{marginLeft: 0, marginRight:'auto'}}>Learn more</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="_350-width">
                            <a href="#use-mobile" className="link-block---persona w-inline-block" style={{height:'100%'}}>
                                <div className="persona" style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}><img src={Mobile} height={200} alt=""
                                                              className="icon centre-mobile"/>
                                    <h5 className="heading-5 centre-mobile">Anywhere & Everywhere</h5>
                                    <p className="paragraph _15px centre-mobile">Use Tracker natively on Web, iOS, and Android</p>
                                    <div className="centre-mobile" style={{marginTop:'auto'}}>
                                        <div className="button w-button" style={{marginLeft: 0, marginRight:'auto'}}>Learn more</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/*<div className="_350-width">*/}
                        {/*    <a href="#use-mobile" className="link-block---persona w-inline-block" style={{height:'100%'}}>*/}
                        {/*        <div className="persona" style={{height:'100%', display:'flex', flexDirection:'column', justifyContent:'flex-start'}}><img src={Mobile} height={200} alt=""*/}
                        {/*                                      className="icon centre-mobile"/>*/}
                        {/*            <h5 className="heading-5 centre-mobile">Anywhere & Everywhere</h5>*/}
                        {/*            <p className="paragraph _15px centre-mobile">Use Tracker natively on Web, iOS, and Android</p>*/}
                        {/*            <div className="centre-mobile" style={{marginTop:'auto'}}>*/}
                        {/*                <div className="button w-button" style={{marginLeft: 0, marginRight:'auto'}}>Learn more</div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="section-standard blue-background">
                <div className="div-standard">
                    <div className="columns-features-page div-standard w-row">
                        <div className="column-76 w-col w-col-6">
                            <div className="div-500-width">
                                <h2 className="heading-2">Included at no extra charge</h2>
                                <p className="paragraph _400w">Tracker is included for all IT maintenance under management via Mark III.  Sign up for a free account and request a maintenance quote to activate Tracker today.</p>
                            </div>
                        </div>
                        <div className="column-80 w-clearfix w-col w-col-6"><img src={Free} width="456" alt=""
                                                                                 className="home-image right-align _20-top-margin"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-standard _40-bottom-padding">
                <div className="div-standard">
                    <h2 className="heading-2 centre _800w">Managing maintenance should be simple</h2>
                    <p className="paragraph centre _550-width">You have more important things to do. Let Tracker (and our team of experts) take care of the details and minutiae of managing your maintenance contracts so that you can focus on your mission.</p>
                </div>
            </div>
            <div id='send-reminders' className="section-standard _0-padding">
                <div className="div-standard">
                    <div className="columns-features reverse-mobile w-row">
                        <div className="column-76 w-col w-col-6 w-col-small-small-stack">
                            <img src={Reminders} width="100%" alt="" className="home-image _1"/>
                        </div>
                        <div className="w-col w-col-6 w-col-small-small-stack">
                            <div className="div-450-width">
                                <h2 className="heading-2 _35px">Send automatic reminders to any key stakeholder</h2>
                                <p className="paragraph _400w">Receive proactive alerts before contracts expire so that you and your team can take action</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='search-information' className="section-standard _0-padding">
                <div className="div-standard">
                    <div className="columns-features w-row">
                        <div className="column-76 w-col w-col-6">
                            <div className="div-450-width">
                                <h2 className="heading-2 _35px">Quickly find the information you need</h2>
                                <p className="paragraph _400w">Search and filter IT contracts and entitlements by OEM/Manufacturer, Model, or Serial Number</p></div>
                        </div>
                        <div className="column-81 w-col w-col-6"><img src={Filters} width="100%"
                                                                      alt="" className="home-image _2"/></div>
                    </div>
                </div>
            </div>
            <div id='use-mobile' className="section-standard _0-padding">
                <div className="div-standard">
                    <div className="columns-features reverse-mobile w-row">
                        <div className="column-76 w-col w-col-6"><img src={AndroidIOS} width="100%"
                                                                      alt="" className="home-image _3"/></div>
                        <div className="w-col w-col-6">
                            <div className="div-450-width">
                                <h2 className="heading-2 _35px">Your assistant on the go</h2>
                                <p className="paragraph _400w">Search your IT assets from iOS or Android to help you open support tickets or verify maintenance status</p>
                                {/*<a href="http://?rfsn=2028432.cbfcb1&amp;utm_medium=affiliate"*/}
                                {/*className="button left-aligned w-button">Get this template</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div id='search-information' className="section-standard _0-padding">*/}
            {/*    <div className="div-standard">*/}
            {/*        <div className="columns-features w-row">*/}
            {/*            <div className="column-76 w-col w-col-6">*/}
            {/*                <div className="div-450-width">*/}
            {/*                    <h2 className="heading-2 _35px">Maintenance Expert Included</h2>*/}
            {/*                    <p className="paragraph _400w">Dedicated Maintenance Guru included for quotes, logistics, and tricky questions</p></div>*/}
            {/*            </div>*/}
            {/*            <div className="column-81 w-col w-col-6"><img src={Filters} width="100%"*/}
            {/*                                                          alt="" className="home-image _2"/></div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="section-standard">
                <div className="div-standard">
                    <h2 className="heading-2 _35px centre">Tracker supports 100+ OEM/Manufacturers including</h2>
                    <img src={LogoList} alt="" width='100%'/>
                </div>
            </div>
            {/*<div className="section-standard blue-background">*/}
            {/*    <div className="div-standard">*/}
            {/*        <div className="final-cta-block">*/}
            {/*            <h1 className="heading-1 white centre _900-width">A strong headline that describes your products*/}
            {/*                big benefit</h1>*/}
            {/*            <div className="form-block _500w centre w-form">*/}
            {/*                <form id="email-form" name="email-form" data-name="Email Form" className="form"><input*/}
            {/*                    type="email" className="text-field w-input" maxLength={256} name="email-2"*/}
            {/*                    data-name="Email 2" placeholder="Your email address" id="email-2" required={true}/>*/}
            {/*                    <a href="#" className="button w-button">Get started</a></form>*/}
            {/*                <div className="w-form-done">*/}
            {/*                    <div>Thank you! Your submission has been received!</div>*/}
            {/*                </div>*/}
            {/*                <div className="w-form-fail">*/}
            {/*                    <div>Oops! Something went wrong while submitting the form.</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}