import React, {useState} from 'react';
import {Form, Input} from 'antd';
import Button from "../../common/Button/Button";
import {TContractLineItem} from "../../../stores/App/Types";

interface Props {
    onSubmit:(contractLineItem:TContractLineItem, values:any)=>void
    contractLineItem:TContractLineItem,
}

export default (props:Props) => {
    const {onSubmit, contractLineItem} = props;
    const [submitting, setSubmitting] = useState(false);

    const onFinish = async (values:any) => {
        if (onSubmit) {
            setSubmitting(true);
            await onSubmit(contractLineItem, values);
            setSubmitting(false);
        }
    };
    let initialValues = { notes: contractLineItem.notes };

    return (
        <Form className='reminders-form' initialValues={initialValues} layout='vertical' name="reminders-form"
              onFinish={onFinish} autoComplete="off" preserve={false}>
            <div className='reminders-form-content'>
               <Form.Item label='Notes' name='notes'>
                    <Input.TextArea style={{resize:"none"}} rows={4}/>
               </Form.Item>
            </div>
            <div className='buttons-container'>
                <Form.Item style={{marginBottom: 0}}>
                    <Button loading={submitting} htmlType="submit">
                        Submit Notes
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};